import { ButtonPrimary } from './Tools';

export function Banner() {
  return (
    <div className='bg-gray-900 mainapp-banner' style={{ backgroundImage: 'url("/bannerbg.jpg")' }}>
      <div className='container p-3 m-auto mainapp-banner-sized flex justify-center flex-col'>
        <div>
          <h3 className='text-yellow-100 text-center text-2xl pb-5 '>We make complex simple.</h3>
          <h1 className='text-6xl text-center text-white pb-10 uppercase text font-bold'>Simplify? Get Bitslyfied!</h1>
          <div className='flex align-middle'>
            <ButtonPrimary>
              <span className='uppercase text-white font-bold'>
                Tell me a <span className='text-yellow-400'>BIT</span> more
              </span>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>);
}
