const footerStyle = {
  backgroundImage: `url("absbg.jpg")`,
  backgroundSize: '500px auto'
};

export function Footer() {
  return <div className='bg-gray-950 min-h-38 p-5' style={footerStyle}>
    <div className='container m-auto'>
      <h2 className="text-black text-center font-semibold">Follow us on our social media pages</h2>
      <div className='flex justify-center'>
        <div className='w-96 text-center text-green-600 font-bold'>
          <div className="fb-page" data-href="https://web.facebook.com/bitslyfy/" data-tabs="" data-width="300" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://web.facebook.com/bitslyfy/" className="fb-xfbml-parse-ignore"><a href="https://web.facebook.com/bitslyfy/">BITSLY I.T. Solutions</a></blockquote></div>
        </div>
      </div>
    </div>
  </div>;
}
