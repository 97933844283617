import { useState } from 'react';
import logo from '../logo.svg';

const navlinks = [
  { label: "Services", target:'#services'},
  { label: "About", target:'#about'},
  { label: "Team", target:'#team'},
  { label: "Contact", target:'#contact'},
]

export function NavBar() {
  const [isOpen, setIsOpen] = useState(false)

  return (<>
    <nav className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <button onClick={()=>setIsOpen(e=>!e)} type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              {/* <!--
          Icon when menu is closed.

          Menu open: "hidden", Menu closed: "block"
        --> */}
              <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              {/* <!--
          Icon when menu is open.

          Menu open: "block", Menu closed: "hidden"
        --> */}
              <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img className="h-9 w-auto" src={logo} alt="BitslyTech IT Solution" />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}

                <NavLinks isOpen={isOpen}/>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      
      <div id="mobile-menu" className={`${!isOpen && 'h-0'} sm:hidden overflow-hidden transition-all duration-100 ease-in-out`}>
        <div className="space-y-1 px-2 pb-3 pt-2">
          <NavLinks/>
          {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
        </div>
      </div>
    </nav>

  </>);
}

function NavLinks({isOpen}){
  return (
    <>
      {navlinks.map(e=> <a key={e.label} href={e.target} className={`${!isOpen && 'block'} rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 hover:text-white`} aria-current="page">{e.label}</a> )}
    </>
  )
}
