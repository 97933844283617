const servicesItems = {
  web: {
    label: 'Web Solutions',
    description: 'We develop web applications tailored to your needs, providing simple, safe, and secure solutions that can replace your manual and complex daily routines.',
    icon: '🚀',
  },
  sp: {
    label: 'Single Page Websites',
    description: 'Creating your own business website, personal blog, or portfolio has never been easier with our help. You envision, we bring it to life.',
    icon: '🌎',
  },
  splunk: {
    label: 'Data Analytics and Reporting',
    description: 'Unlock the power of your data with our expert analytics services, backed by over 7 years of experience. We specialize in creating insightful reports, interactive dashboards, and efficient records management to drive your business forward.',
    icon: "📊",
  },
  game: {
    label: 'HTML Game Development',
    description: "Unlock your game's full potential with our expert development services, tailored to bring your vision to life. From concept to launch, we ensure a seamless and captivating gaming experience.",
    icon: "⚾️",
  }
};
export function Services({ onSetInquiry }) {
  return (<div className='p-6 md:p-10 section bg-gray-900' id="services">
    <div className='section container m-auto'>
      <h2 className='text-green-600 text-4xl font-bold text-center'>Services</h2>
      <p className='text-center italic text-white max-w-lg m-auto mt-5'>The team's goal is to simplify your complex, tiring, and time-consuming manual processes. Here are the major services we offer:</p>
      <div className='mt-20 grid grid-cols-1 md:grid-cols-2 gap-4'>
        {Object.keys(servicesItems).map(e => <ServicesItems key={e} data={servicesItems[e]} onSetInquiry={onSetInquiry} />)}
      </div>
    </div>
  </div>);
}
function ServicesItems({ data, onSetInquiry }) {
  return <>
    <div className={`p-10 bg-slate-700 rounded-lg `}>
      <div className='flex flex-row justify-start'>
        <h2 className='text-3xl text-green-50 text-left font-bold'>{data.label}</h2>
        <div> &nbsp; <span className='text-4xl md:text-2xl leading-1'>{data.icon}</span></div>
      </div>
      <p className='text-white  p-l-20'>{data.description}</p>
      <div className='flex flex-row justify-end'>
        <a href='#contact' className='text-green-500 font-bold pt-4 hover:text-green-300' onClick={e => onSetInquiry(`Interested in your ${data.label.toLowerCase()} services`)}>Inquire now  <i className="fa fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </>;
}
