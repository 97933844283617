const teamData = [
  { name: 'Rodeline Alcantara', role: 'Web Developer', img: 'odie.jpg' },
  { name: 'Ronald Mendoza', role: 'Web Developer', img: 'nomu.png' },
  { name: 'Dan Edward Matundan', role: 'Game Developer', img: 'edward.png' }
];

const teamStyle = {
  backgroundImage: `url("absbg.jpg")`
};

export function Team() {
  
  return (
    <>
      <div className='p-6 md:p-10 section team-section' id="team" style={teamStyle}>
        <div className='container m-auto '>
          <div className='block lg:hidden p-3 bg-white rounded-lg'>
            <h2 className='text-green-600 text-center text-4xl font-bold'>Bitslyfiers</h2>
            <p className='p-4 px-4 md:px-10 text-left text-center'>We are highly skilled developers who offer top-tier solutions for all your IT requirements. Show your support by engaging them to assist with your business or personal IT needs.</p>
          </div>
          <div className='grid grid-cols-3 mt-5 gap-4'>
            <div className='col-span-3 md:col-span-1 flex justify-center flex-col text-center bg-gray-900 rounded-lg pt-5 pb-7'>
              <img src='lead.png' alt='Darl Jed Matundan' className='w-9/12 mx-auto' />
              <h2 className='text-2xl mt-3 font-thin text-green-600'>Lead Developer</h2>
              <p className='text-center text-white'>Darl Jed Matundan</p>
            </div>
            <div className='col-span-3 py-6 lg:py-10 md:col-span-2 flex flex-row flex-wrap justify-center bg-gray-900 rounded-lg text-white'>
              <div className='col-span-3 hidden lg:block ld:p-4'>
                <h2 className='text-green-600 text-center text-4xl font-bold'>Bitslyfiers</h2>
                <p className='p-4 px-4 lg:px-20 md:px-10 block text-center'>We are highly skilled developers who offer top-tier solutions for all your IT requirements. Show your support by engaging them to assist with your business or personal IT needs.</p>
              </div>

              <TeamMembers data={teamData[0]} />
              <TeamMembers data={teamData[1]} />
              <TeamMembers data={teamData[2]} />
            </div>
          </div>


        </div>
      </div>
    </>);
}
function TeamMembers({ data }) {
  return <div className='flex flex-col mt-2 mb-2  w-full md:w-2/4 lg:w-4/12 items-center'>
    <div style={{ backgroundImage: `url("${data.img}")` }} className='team-members-card w-2/4 md:w-5/12 lg:w-3/4 mx-auto my-2 lg:my-5 aspect-square border-2 border-gray-900' />
    <h2 className='text-center font-bold text-green-600'>{data.name}</h2>
    <p className='text-center'>{data.role}</p>
  </div>;
}
