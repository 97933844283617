import { useState } from 'react';
import '../App.css';
import 'font-awesome/css/font-awesome.min.css';
import { NavBar } from './NavBar';
import { Team } from './Team';
import { About } from './About';
import { Services } from './Services';
import { Banner } from './Banner';
import { Contact } from './Contact';
import { Footer } from './Footer';

import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/privacy-policy" element={<Index privacyPolicy={true} />}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

function Index({privacyPolicy = false}){
  const [inquiry, setInquiry] = useState('Interested in your web solutions services.')
  return (<>
  <NavBar />
  <Banner />
  <Services onSetInquiry={setInquiry}/>
  <Team/>
  <Contact privacyPolicy={privacyPolicy} inquiry={inquiry} setInquiry={setInquiry}/>
  <About/>
  <Footer/>
  </>)
}
