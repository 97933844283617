export function About() {
  return (<div className='bg-gray-900'>
    <div className='p-6 md:p-10 container m-auto' id="about">
      <div className='p-3'>
        <h2 className='text-green-600 text-4xl font-bold'><span className='text-3xl'></span> About us</h2>
        <div className='grid grid-cols-2 gap-3'>
          <AboutBox>
            <h2 className='text-2xl font-bold text-white'>Our History</h2>
            <AboutBoxParagraph>Our journey began as a group of college students with a shared passion for web platforms. During our studies, we immersed ourselves in learning and experimenting with the latest web technologies. After graduation, we each pursued careers as software and web developers, gaining valuable experience in various industries.</AboutBoxParagraph>
            <AboutBoxParagraph>Despite our individual paths, our shared enthusiasm for web development brought us back together. We decided to pool our talents and take on freelance projects, delivering high-quality solutions to clients. Our collaboration was so successful that we formalized our partnership, and thus, Bitsly IT Solution was born.</AboutBoxParagraph>
          </AboutBox>
          <AboutBox>
            <h2 className='text-2xl font-bold text-white'>Who We Are</h2>
            <AboutBoxParagraph>Bitsly IT Solution is a dedicated team of professionals specializing in web development and related services. Our members bring their own unique expertise and extensive experience to every project, ensuring that we produce quality products that meet the highest standards. Our commitment to excellence has consistently resulted in high levels of client satisfaction.</AboutBoxParagraph>

            <h2 className='text-2xl font-bold text-white'>Our Vision</h2>
            <AboutBoxParagraph>We envision a future where technology simplifies everyday tasks, making life easier for everyone. As we grow in skills and experience, we aim to broaden our service offerings to include other IT-related solutions, further enhancing our ability to serve our clients.</AboutBoxParagraph>

          </AboutBox>
          <div className='col-span-2 text-center'>
            <AboutBoxParagraph>Thank you for choosing <span className='text-green-600 font-bold'>Bitsly IT Solution</span>. We look forward to partnering with you to turn your vision into reality.</AboutBoxParagraph>
          </div>
          
        </div>


      </div>
    </div>
  </div>);
}
function AboutBox({ children }) {
  return <div className='col-span-2 md:col-span-1'>{children}</div>;
}
export function AboutBoxParagraph({ children }) {
  return <p className='p-5 leading-7 text-white  font-extralight'>{children}</p>;
}
